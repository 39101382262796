.outerContainer {
  @apply flex relative justify-center sm:p-0 px-24 pt-40 w-full;
}

.innerContainer {
  @apply flex
  z-[1]
  flex-col
  lg:flex-row
  justify-between
  items-center
  p-24
  md:p-40
  w-full
  max-w-screen-xl
  bg-display-100
  md:bg-display-0
  rounded-t-2xl
  rounded-b-2xl
  sm:rounded-tl-2xl
  sm:rounded-tr-[0px]
  shadow-new;

  & h3 {
    @apply w-full max-w-full lg:max-w-[250px] text-center lg:text-left break-words heading-3;
  }
}

.halfBg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: transparent;
}

.halfBg-bg::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-color: #e3342f;
}
