.container {
  @apply max-w-full max-h-[480px] min-w-[272px] h-full flex relative items-center justify-center;
}

.video_container {
  @apply h-0 w-full max-w-full overflow-hidden relative pb-[56.25%];

  & iframe,
  & object,
  & embed {
    @apply absolute top-0 left-0 w-full h-full;
  }
}

.play_button_container {
  @apply absolute bg-stone-500 cursor-pointer flex items-center justify-center z-10 md:h-[72px] md:w-[72px] h-[48px] w-[48px];
  box-shadow: 2px 12px 24px rgba(103, 116, 129, 0.2);
  border-radius: 50%;
  margin: auto;

  &:hover {
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.5);
  }
}
