.tickerBanner {
  background: linear-gradient(90.05deg, #d2f961 0%, #6dffb9 99.91%);
}

.tickerDiv {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
}

.tickerDiv .phrase {
  display: flex;
  align-items: center;
  padding-left: 100%;
  animation: 250s linear infinite running tickerDiv;
}

.tickerDiv .phrase:hover {
  animation-play-state: paused;
}

.tickerDiv span {
  display: inline-flex;
  align-items: center;
  margin-right: 36px;
}
.tickerDiv span p {
  font-family: Sharon Display;
  margin-right: 16px;
  font-size: 1rem;
}

@keyframes tickerDiv {
  0% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 880px) {
  .tickerBanner {
    height: 60px;
  }

  .tickerDiv {
    font-size: 1.3rem;
  }
}
