.menuContainer {
  @apply flex flex-col h-full justify-center cursor-default;

  & .menu,
  & .menuProducts {
    @apply opacity-0 transition-all border-t border-solid border-display-200;
  }

  & + .menuOverlay {
    @apply opacity-0 transition-all;
  }

  &:hover {
    @apply rounded-t-md;
    height: calc(100% + 16px);
    box-shadow: inset 0px 1px 0px #e6ecf2, inset 1px 0px 0px #e6ecf2,
      inset -1px 0px 0px #e6ecf2, inset 0px -1px 0px #ffffff;

    &::before {
      content: '';
      @apply absolute left-0 -bottom-2 w-[calc(100%-2px)] h-3 bg-white mx-1 z-10;
    }

    & .menu,
    & .menuProducts {
      @apply visible opacity-100;
    }

    & + .menuOverlay {
      @apply block visible opacity-100;
    }
  }
}

.customMenuContainer {
  @apply flex flex-col h-full justify-center cursor-default;

  & .menu,
  & .menuProducts {
    @apply opacity-0 transition-all border-t border-solid border-display-200;
  }

  & + .menuOverlay {
    @apply opacity-0 transition-all;
  }

  &:hover {
    @apply rounded-t-md;
    height: calc(100% + 16px);
    box-shadow: 0px 16px 16px rgba(103, 116, 129, 0.15),
      inset 0px -1px 0px #e6ecf2, inset 1px 0px 0px #e6ecf2,
      inset -1px 0px 0px #e6ecf2;

    @apply bg-white;

    & > div {
      @apply !text-display-900;
    }

    &::before {
      content: '';
      @apply absolute left-0 -bottom-2 w-[calc(100%-2px)] h-3 bg-white mx-1 z-10;
    }

    & .menu,
    & .menuProducts {
      @apply visible opacity-100;
    }

    & + .menuOverlay {
      @apply block visible opacity-100;
    }
  }
}

.menuOverlay {
  @apply absolute w-full h-[calc(100vh+710px)] left-0 top-[64px] hidden;
  background-color: rgba(32, 37, 42, 0.3);
}

.menuProducts {
  @apply flex flex-row invisible absolute top-[calc(100%)] bg-display-0 h-[fit-content] rounded-b-2xl w-[calc(100vw-32px)] max-w-[1280px];
  box-shadow: 0px 16px 16px rgba(103, 116, 129, 0.15),
    inset 0px -1px 0px #e6ecf2, inset 1px 0px 0px #e6ecf2,
    inset -1px 0px 0px #e6ecf2;

  & > div > a {
    @apply cursor-pointer mx-1 pt-24;
  }
}

.menu {
  @apply invisible absolute top-full bg-display-0 rounded-b-md min-w-[345px] py-16;
  box-shadow: 0px 16px 16px rgba(103, 116, 129, 0.15),
    inset 0px -1px 0px #e6ecf2, inset 1px 0px 0px #e6ecf2,
    inset -1px 0px 0px #e6ecf2;

  & > div > a {
    @apply cursor-pointer mx-1 w-auto;

    &:hover {
      @apply bg-display-50;

      & > div > div > p:first-child {
        @apply text-stone-500;
      }

      & > svg {
        @apply text-stone-500;
      }

      & .submenu {
        @apply visible;
      }
    }

    @apply p-16;
  }
}

.submenu {
  @apply absolute top-0 overflow-hidden invisible -right-full bg-display-0 rounded-b-md w-[345px] py-16;
  box-shadow: 1px 16px 16px -2px rgba(103, 116, 129, 0.15);

  & > a {
    @apply p-16 cursor-pointer mx-1;

    &:hover {
      @apply bg-display-50;

      & p:first-child,
      & svg {
        @apply text-stone-500;
      }
    }
  }
}

.menuMobileRightIcons {
  @apply flex mr-[-8px] items-center gap-8;

  & > button {
    @apply w-48 h-48 flex items-center justify-center text-stone-500;
  }

  & button.active {
    @apply bg-display-100 rounded-full text-display-900;
  }
}

.menuIcon {
  @apply flex flex-col gap-4;

  & span {
    width: 18px;
    height: 2px;
    @apply bg-stone-500 rounded-lg transition-transform;
  }
}

.menuIcon2 {
  @apply flex flex-col gap-4;

  & span {
    width: 18px;
    height: 2px;
    @apply bg-stone-300 rounded-lg transition-transform;
  }
}

.active {
  @apply gap-0 relative items-center justify-center;

  & span {
    @apply bg-display-900 transform absolute;

    &:nth-child(1) {
      @apply rotate-45;
    }

    &:nth-child(2) {
      @apply hidden;
    }

    &:nth-child(3) {
      @apply -rotate-45;
    }
  }
}

.menuDrawer {
  @apply w-full z-50 absolute hidden overflow-hidden bg-display-900  right-0 bg-opacity-50 h-[calc(100%-64px)];

  & .menuMobile,
  & .customerMenuMobile {
    @apply w-full h-full bg-display-0 ml-auto max-w-[500px];
  }
}

.menuMobile {
  @apply flex flex-col;
}

.customerMenuDrawer {
  @apply w-full hidden overflow-hidden absolute bg-display-900 right-0 bg-opacity-0;

  @screen xs {
    height: calc(100vh - 64px);
  }

  @screen md {
    height: calc(100vh - 72px);
  }

  & .menuMobile,
  & .customerMenuMobile {
    @apply w-full h-full bg-display-100 ml-auto max-w-[500px];
  }
}
