.listContainer {
  @apply min-h-[320px];
}

.listContainer :local(.listItem) {
  @apply first:rounded-t-[24px] last:rounded-b-[24px] md:!rounded-none;
}

.listItem {
  @apply relative overflow-hidden;
}

.listItem:first-child :local(.listItemImage) {
  & > div {
    @apply rounded-t-[24px] border-t-[1px] border-display-400 md:!rounded-none;
  }
}

.listItem:first-child :local(.listItemCard) {
  @apply rounded-t-[24px] md:!rounded-none;
}
.listItem:last-child :local(.listItemCard) {
  @apply border-b-[1px] rounded-b-[24px] border-display-400 md:!rounded-none;
}

.listItem:global(.listItemActive) {
  @apply relative overflow-hidden z-0;
  overflow: unset;
}

.listItemImage {
  @apply w-full overflow-hidden hidden justify-center;

  & > div {
    @apply border-l-[1px] border-r-[1px] border-t-[1px] border-display-400;
  }
}

.listItemCardBase {
  @apply relative min-h-[84px] p-16;
}

.listItemCard {
  composes: listItemCardBase;
  @apply bg-display-0 mx-24 md:mr-40 md:ml-12 lg:mx-16 border-l-[1px] border-r-[1px] border-t-[1px] cursor-pointer border-display-400;
  & p {
    @apply text-display-900;
  }
}

.listItemCardActive {
  composes: listItemCardBase;
  @apply bg-stone-500;

  & p {
    @apply text-display-0;
  }
}

.listItem:global(.listItemActive) :local(.listItemCard) {
  @apply bg-stone-500 !rounded-[8px] mx-16 md:mx-24 md:ml-0 lg:mx-0;

  & p {
    @apply text-display-0;
  }
}

.listItem:global(.listItemActive) :local(.listItemImage) {
  @apply h-[320px] flex md:hidden;
}
