.active {
  @apply border-newTon-700;
}

.cardActive {
  @apply flex;
}

.cardInactive {
  @apply hidden md:flex;
}

.progress-bar {
  height: 100%;
  width: 4px;
  writing-mode: vertical-lr;
}
.progress-bar::-webkit-progress-value {
  background-color: #00a868;
}

.progress-bar::-webkit-progress-bar {
  background-color: #e6ecf2;
}
