.tickerDiv {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
}

.tickerDiv .phrase {
  display: flex;
  align-items: center;
  padding-left: 100%;
  animation: 700s linear infinite running tickerDiv;
}

.tickerDiv .phrase:hover {
  animation-play-state: paused;
}

.tickerDiv span {
  display: inline-flex;
}

@keyframes tickerDiv {
  0% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 880px) {
  .tickerDiv {
    font-size: 1.3rem;
  }
}
