.tickerDiv {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
}

.tickerDiv .phrase {
  display: flex;
  align-items: center;
  padding-left: 100%;
  animation: 250s linear infinite running tickerDiv;
}

.tickerDiv .phrase:hover {
  animation-play-state: paused;
}

@keyframes tickerDiv {
  0% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 880px) {
  .tickerBanner {
    height: 60px;
  }

  .tickerDiv {
    font-size: 1.3rem;
  }
}

.tickerBadge {
  position: relative;
  background: linear-gradient(
    90deg,
    rgba(0, 168, 104, 0.3),
    rgba(236, 102, 11, 0.3)
  );
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 6px 16px;
}

.tickerBadge::before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 24px;
  padding: 2px;
  background: linear-gradient(
    90deg,
    rgba(0, 168, 104, 1),
    rgba(236, 102, 11, 1)
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
