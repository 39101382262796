.Active {
  width: 112px;
  transition: width 500ms;
}

.MidText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  transition: width 500ms;
}

.FullText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 1;
  transition: all 500ms;
  width: 264px;

  @media screen and (min-width: theme('screens.lg')) {
    width: 300px;
  }
}

.NoWidth {
  width: 0px;
  opacity: 0;
  transition: all 400ms;
}

.GreenCircle {
  margin: unset !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 24px;
  height: 24px;
  transition: all 500ms;
}

.GreenCircleBig {
  width: 32px;
  height: 32px;

  @media screen and (min-width: theme('screens.lg')) {
    width: 42px;
    height: 42px;
  }
}

.NoMargin {
  margin-left: unset;
}

.AlignRight {
  left: unset;
  right: 8px;
}
