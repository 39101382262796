.container {
  @apply flex border-t-display-800 border-t-[1px] gap-32;
}

.product {
  @apply flex flex-col text-[10px] md:text-xs cursor-pointer gap-4;
  @apply border-t-[3px] border-solid border-transparent text-display-0 items-center text-center;

  & svg {
    margin-top: 12px;

    & path {
      fill: #31383f;
    }
  }
  transition: all 0.6s;
}

.productActive,
.product:hover {
  @apply border-t-stone-500;

  & div {
    @apply mt-auto max-h-full;
  }

  & svg path {
    fill: white;
  }
}
