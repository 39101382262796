.buttonContainer {
  @apply font-sharon flex justify-center items-center cursor-pointer font-medium whitespace-nowrap;
  border-radius: 32px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  text-decoration: none;
}

.primary {
  @apply bg-stone-500 text-display-0;

  &:hover {
    box-shadow: 0 0 4px 2px rgba(66, 236, 154, 0.5);
    background-color: rgba(0, 142, 90, 1);
  }

  &:disabled {
    border-color: rgba(230, 236, 242, 1);
    background-color: rgba(230, 236, 242, 1);
    color: rgba(255, 255, 255, 1);
    box-shadow: none;
    cursor: default;
  }

  &:disabled:active {
    box-shadow: none;
    background-color: rgba(230, 236, 242, 1);
  }

  &:active,
  &:focus,
  &:focus-visible,
  &:focus-within {
    background-color: rgba(0, 168, 104, 1);
    box-shadow: 0 0 4px 2px rgba(66, 236, 154, 0.5);
  }
}

.primary.disabled {
  border-color: rgba(230, 236, 242, 1);
  background-color: rgba(230, 236, 242, 1);
  color: rgba(255, 255, 255, 1);
  box-shadow: none;
  cursor: not-allowed;
}

.stonePrimary {
  @apply bg-stone-300 hover:bg-stone-400 text-display-900;
  &:hover {
    box-shadow: 0 0 4px 2px rgba(66, 236, 154, 0.5);
  }

  &:active,
  &:focus,
  &:focus-visible,
  &:focus-within {
    box-shadow: 0 0 8px 4px rgba(66, 236, 154, 0.5);
    background-color: rgba(0, 168, 104, var(--tw-bg-opacity));
  }
}

.secondary {
  @apply bg-display-0 text-display-900 border-solid border-[1px] border-display-400;
  &:hover {
    box-shadow: 0 0 4px 2px rgba(66, 236, 154, 0.5);
    border-color: rgba(232, 254, 243, 1);
    background-color: rgba(232, 254, 243, 1);
  }

  &:active {
    background-color: rgba(66, 236, 154, 1);
    border-color: rgba(15, 204, 125, 1);
    box-shadow: 0 0 8px 4px rgba(66, 236, 154, 0.5);
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    border-color: rgba(15, 204, 125, 1);
    box-shadow: 0 0 8px 4px rgba(66, 236, 154, 0.5);
  }
}

.ghost {
  @apply bg-transparent text-display-0 border-solid border-2 border-display-0;
  &:hover {
    background-color: rgba(255, 255, 255, 1);
    color: black;
  }

  &:active,
  &:focus,
  &:focus-visible,
  &:focus-within {
    border-color: rgba(249, 250, 251, 1);
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 8px 4px #fff;
    color: black;
  }
}

.regular {
  padding: 0 16px;
}

.large {
  font-size: 16px;
  line-height: 24px;
  height: 56px;
  padding: 0 24px;
}
