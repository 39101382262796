.planDetailsContainer {
  @apply flex h-full w-full flex-col overflow-y-auto bg-display-0 p-24 pb-[100px] md:h-auto md:rounded-md md:p-24;

  & {
    scrollbar-color: #0fcc7d transparent;
  }

  & ::-webkit-scrollbar {
    @apply w-[8px] h-[8px];
  }

  & ::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  & ::-webkit-scrollbar-thumb {
    @apply border-[2px] border-solid border-display-0 bg-display-400 rounded-[24px];
  }
}

.scrollTable {
  & {
    scrollbar-color: #bcc8d6 transparent;
  }
}

.box {
  box-shadow: 4px 0 5px -2px rgba(0, 0, 0, 0.15);
}
