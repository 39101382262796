.video_container {
  @apply h-0 w-full max-w-full overflow-hidden relative pb-[56.25%] bg-black rounded-t-2xl md:rounded-2xl lg:rounded-6xl;

  & iframe,
  & object,
  & embed {
    @apply absolute top-0 left-0 w-full h-full;
  }
}

.play_button_container {
  @apply absolute bg-black/50 cursor-pointer flex items-center justify-center z-10 lg:h-[100px] lg:w-[100px] h-[64px] w-[64px] transition-shadow;
  box-shadow: 2px 12px 24px rgba(103, 116, 129, 0.2);
  border-radius: 50%;
  margin: auto;

  &:hover {
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  }
}

.Shadow {
  @apply absolute bottom-0 hidden md:flex w-full h-full md:min-h-[168px] lg:min-h-[212px];
  background: linear-gradient(
    360deg,
    rgba(0, 92, 65, 50) 6.39%,
    rgba(0, 92, 65, 0) 90.6%
  );
}
